/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import {Link} from 'react-router-dom'
export function FunnelWidgets({
  className,
  baseColor = "dark",
  widgetHeight = "150px",
  funCount
}) {
  
  return (
    <>
      <div
        className={`card card-custom  ${className}`}
        style={{ height: widgetHeight, backgroundColor:"#543278" }}
      >
        <div className="card-body">
          <span className="svg-icon svg-icon-3x svg-icon-white ml-n2">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Funnel.svg")} />
          </span>
          <div
            className={`text-inverse-${baseColor} font-weight-bolder font-size-h2 mt-3`}
          >
           {funCount}
          </div>

          <Link  to={`/funnels/fun`}
          
            className={`text-inverse-${baseColor} font-weight-bold font-size-lg mt-1`}
          >
            Your Funnels
          </Link>
        </div>
      </div>
    </>
  );
}
