import { createSlice } from "@reduxjs/toolkit";

const initialfunnelsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  cats: null,
  reps: null,
  funnelForEdit: undefined,
  lastError: null,
  owners: null,
  leaders: null,
  categories: null,
  rewards: null,
  servers: null,
  days: null,
  price: null,
  pdf: null,

};
export const callTypes = {
  list: "list",
  action: "action"
};

export const funnelsSlice = createSlice({
  name: "funnels",
  initialState: initialfunnelsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {

        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getfunnelById
    funnelFetched: (state, action) => {
      const { funnelForEdit } = action.payload;
      state.actionsLoading = false;
      state.funnelForEdit = funnelForEdit;
      state.error = null;
    },

    // addReptoFunnl
    repAdded: (state, action) => {
      const { funnelForEdit } = action.payload;
      state.actionsLoading = false;
      state.funnelForEdit = funnelForEdit;
      state.error = null;
    },

    // removeReptoFunnl
    repRemovedFromFunnel: (state, action) => {
      const { funnelForEdit } = action.payload;
      state.actionsLoading = false;
      state.funnelForEdit = funnelForEdit;
      state.error = null;
    },

    // GetDropDowns
    dropDownFetched: (state, action) => {
      const { reps, owners, leaders, categories, rewards, servers } = action.payload;
      state.actionsLoading = false;
      state.reps = reps
      state.owners = owners
      state.leaders = leaders
      state.categories = categories
      state.rewards = rewards
      state.servers = servers
      state.error = null;
    },

    // findfunnels
    funnelsFetched: (state, action) => {
      const { totalCount, entities, reps, cats, servers } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.reps = reps;
      state.cats = cats;
      state.servers = servers;
      state.totalCount = totalCount;
    },
    myFunnelsFetched: (state, action) => {
      const { totalCount, entities, reps, cats } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.reps = reps;
      state.cats = cats;
      state.totalCount = totalCount;
    },

    funnelsProfileFetched: (state, action) => {
      const { funnels, reps } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.reps = reps;
      state.funnels = funnels;
    },
    // createfunnel
    funnelCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.funnel);
    },
    // updatefunnel
    funnelUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.funnel.id) {
          return action.payload.funnel;
        }
        return entity;
      });
    },
    // deletefunnel
    funnelDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deletefunnels
    funnelsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // funnelsUpdateState
    funnelsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    pdfFileCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { pdf } = action.payload;
      var fileDownload = require('js-file-download');
      fileDownload(pdf,'funnellists.pdf')
    },
    
  
  }
});
