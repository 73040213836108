import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "./authCrud";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  Pending: "[Pending] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
  UserUpdae: "[User Update] Action",
  UpdateBank: "[Update Bank] Action",
  DashboardStatus: "[Dashboard Status] Action",
  HomeTableValue: "[HomeTableValue Status] Action",
  ChangePassword: "[Change Password] Action",
  RequestPassword:"Request Password] Action",
};

const initialAuthState = {
  user:undefined,
  authToken: undefined,

};

export const reducer = persistReducer(
  { storage, key: "v726-demo2-auth", whitelist: ["authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }


      case actionTypes.Pending: {
        const { authToken } = action.payload;

        return { user: undefined };
      }

    

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }
      

      case actionTypes.UserUpdae: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.UpdateBank: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.DashboardStatus: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.HomeTableValue: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.ChangePassword: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.RequestPassword: {
        const { email } = action.payload;
        return { ...state, email };
      }
      

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }


      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken) => ({ type: actionTypes.Login, payload: { authToken } }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  pending: (authToken) => ({
    type: actionTypes.Pending,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  userUpdate: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),

  updateBank: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),

  dashboardStatus: (user) => ({
    type: actionTypes.DashboardStatus,
    payload: { user },
  }),

  homeTableValue: (user) => ({
    type: actionTypes.HomeTableValue,
    payload: { user },
  }),

  changePassword: (user) => ({
    type: actionTypes.ChangePassword,
    payload: { user },
  }),

  requestPassword: (email) => ({
    type: actionTypes.RequestPassword,
    payload: { email },
  }),


  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),

  
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });


  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Pending, function* pendingSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: user } = yield getUserByToken();

    yield put(actions.fulfillUser(user));
  });


  yield takeLatest(actionTypes.UserUpdae, function* userUpdated() {
    yield put(actions.userUpdate());
  });

  yield takeLatest(actionTypes.UpdateBank, function* updateBank() {
    yield put(actions.updateBank());
  });

  yield takeLatest(actionTypes.DashboardStatus, function* dashboardStatused() {
    yield put(actions.dashboardStatus());
  });

  yield takeLatest(actionTypes.HomeTableValue, function* hometablevalued() {
    yield put(actions.homeTableValue());
  });

  yield takeLatest(actionTypes.ChangePassword, function* changedPassword() {
    yield put(actions.changePassword());
  });

  yield takeLatest(actionTypes.RequestPassword, function* requestPassword() {
    yield put(actions.requestPassword());
  });
  
}
