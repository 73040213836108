import axios from "axios";

export const CUSTOMERS_URL = `${process.env.REACT_APP_API_URL}/api/transactions`;

// CREATE =>  POST: add a new customer to the server
export function createCustomer(server) {
  return axios.post(`${CUSTOMERS_URL}/register`, { server });
}

// READ
export function getAllCustomers() {
  return axios.get(CUSTOMERS_URL);
}

export function getCustomerById(customerId) {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/transactions/byid/${customerId}`);
}

export function getPaidById(id) {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/transactions/getpaidbyid/${id}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findCustomers(queryParams) {
  return axios.post(`${CUSTOMERS_URL}/find`, { queryParams });
}



// UPDATE => PUT: update the customer on the server
export function updateCustomer(servers) {
  return axios.put(`${process.env.REACT_APP_API_URL}/api/transactions/edit/${servers.id}`, { servers });
}

// UPDATE Status
export function updateStatusForCustomers(ids, status) {
  return axios.post(`${CUSTOMERS_URL}/updatestatusfortransactions`, {
    ids,
    status
  });
}

// DELETE => delete the customer from the server
export function deleteCustomer(customerId) {
  return axios.delete(`${process.env.REACT_APP_API_URL}/api/transactions/delete/${customerId}`);
}

// DELETE Customers by ids
export function deleteCustomers(ids) {
  return axios.post(`${CUSTOMERS_URL}/deletes`, { ids });
}
