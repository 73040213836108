import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import {customersSlice} from "../app/modules/Leads/_redux/customers/customersSlice";
import {funnelsSlice} from "../app/modules/Funnels/_redux/funnels/funnelsSlice";
import {repsSlice} from "../app/modules/Reps/_redux/reps/repsSlice";
import {dashboardSlice} from "../_metronic/_partials/dashboards/_redux/dashboard/dashboardSlice";
import {ServerSlice} from "../app/modules/Servers/_redux/customers/customersSlice";
import {AccountSlice} from "../app/modules/Accounts/_redux/customers/customersSlice";
import {TransactionsSlice} from "../app/modules/Transactions/_redux/customers/customersSlice";
import {ProInfosSlice} from "../app/modules/ProInfos/_redux/reps/repsSlice";
export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  servers: ServerSlice.reducer,
  accounts: AccountSlice.reducer,
  proInfos: ProInfosSlice.reducer,
  transactions: TransactionsSlice.reducer,
  reps:repsSlice.reducer,
  dashboard: dashboardSlice.reducer,
  funnels: funnelsSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
