import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import { Button } from "@material-ui/core";

export const CopyLinkModalFunnel = ({ code }) => {
  const [show, setShow] = useState(false);

  const copy = async () => {
    await navigator.clipboard.writeText(
      `${process.env.REACT_APP_MAIN_URL}/checkout/${code ? code : ""}`
    );
  };
  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  return (
    <>
      <Button
        className="btn btn-light btn-hover-primary"
        onClick={() => {
          handleShow();
        }}
      >
        {code ? code : ""}
      </Button>

      <Modal
        show={show}
        onHide={() => {
          handleClose();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Copy to Clipboard</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Do you want to copy Referral Link to Clipboard ?
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-light btn-hover-secondary"
            onClick={() => {
              handleClose();
            }}
          >
            No
          </Button>
          <Button
            className="btn btn-primary btn-hover-success ml-3"
            onClick={() => {
              copy();
              handleClose();
            }}
          >
            Copy To Clipboard
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
