import axios from "axios";

export const funnels_URL = `${process.env.REACT_APP_API_URL}/api/fun`;



// CREATE =>  POST: add a new funnel to the server
export function createdashboard(funnel) {
  return axios.post(`${funnels_URL}/register`, { funnel });
}

// READ
export function getAlldashboards() {
  return axios.get(funnels_URL);
}

export function getdashboardById() {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/dashboard/`);
}

export function homeTableValueByValue(id) {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/dashboard/${id}`);
}

// DELETE => delete the funnel from the server
export function deletedashboard(funnelId) {
  return axios.delete(`${process.env.REACT_APP_API_URL}/api/fun/delete/${funnelId}`);
}

// DELETE funnels by ids
export function deletedashboards(ids) {
  return axios.post(`${funnels_URL}/deletes`, { ids });
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function finddashboards(queryParams) {
  return axios.post(`${funnels_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the funnel on the server
export function updatedashboard(funnel) {
  return axios.put(`${process.env.REACT_APP_API_URL}/api/fun/edit/${funnel.id}`, { funnel });
}
