import axios from "axios";

export const CUSTOMERS_URL = `${process.env.REACT_APP_API_URL}/api/leads`;

// CREATE =>  POST: add a new customer to the server
export function createCustomer(customer) {
  return axios.post(`${CUSTOMERS_URL}/zarpaycreatelead`, { customer });
}

// READ
export function getAllCustomers() {
  return axios.get(CUSTOMERS_URL);
}

export function getCustomerById(customerId) {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/leads/byid/${customerId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findCustomers(queryParams) {
  return axios.post(`${CUSTOMERS_URL}/find`, { queryParams });
}

export function findCustomersType(queryParams, id) {
  return axios.post(`${CUSTOMERS_URL}/findtype`, { queryParams, id });
}



// UPDATE => PUT: update the customer on the server
export function updateCustomer(customer) {
  return axios.put(`${process.env.REACT_APP_API_URL}/api/leads/edit/${customer.id}`, { customer });
}

// UPDATE Status
export function updateStatusForCustomers(ids, status) {
  return axios.post(`${CUSTOMERS_URL}/updateStatusforleads`, {
    ids,
    status
  });
}

// DELETE => delete the customer from the server
export function deleteCustomer(customerId) {
  return axios.delete(`${process.env.REACT_APP_API_URL}/api/leads/delete/${customerId}`);
}

// DELETE Customers by ids
export function deleteCustomers(ids) {
  return axios.post(`${CUSTOMERS_URL}/deletes`, { ids });
}
