import axios from "axios";

export const funnels_URL = `${process.env.REACT_APP_API_URL}/api/fun`;

// CREATE =>  POST: add a new funnel to the server
export function createfunnel(funnel) {
  return axios.post(`${funnels_URL}/register`, { funnel });
}

export function addreptofunnel(funnelForEdit) {
  return axios.post(`${funnels_URL}/addrep`, { funnelForEdit });
}

// DELETE => delete Rep From Funnel
export function deleterepfromfunnel(funnelForEdit) {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/api/fun/deleterepfromfunnel`,
    funnelForEdit
  );
}

// READ
export function getAllfunnels() {
  return axios.get(funnels_URL);
}

export function cleanAllFunnel() {
  return axios.get(`${funnels_URL}/clean`);
}

export function getfunnelById(funnelId) {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/fun/byid/${funnelId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findfunnels(queryParams) {
  return axios.post(`${funnels_URL}/find`, { queryParams });
}

export function calculateServices(funnelForEdit) {
  return axios.post(`${funnels_URL}/calculate`, { funnelForEdit });
}

export function findmyfunnels(queryParams) {
  return axios.post(`${funnels_URL}/find/my`, { queryParams });
}

export function findfunnelsProfile() {
  return axios.get(`${funnels_URL}/profile`);
}

export function fetchForDropdown() {
  return axios.get(`${funnels_URL}/fetchdropdown`);
}

// UPDATE => PUT: update the funnel on the server
export function updatefunnel(funnel) {
  return axios.put(
    `${process.env.REACT_APP_API_URL}/api/fun/edit/${funnel.id}`,
    { funnel }
  );
}

// UPDATE Status
export function updateStatusForfunnels(ids, status) {
  return axios.post(`${funnels_URL}/updateStatusForfunnels`, {
    ids,
    status,
  });
}

export function createPdfFromFunnels(ids) {
  return axios.post(
    `${funnels_URL}/createpdffromfunnels`,
    {
      ids,
    },
    { responseType: "blob" }
  );
}

// DELETE => delete the funnel from the server
export function deletefunnel(funnelId) {
  return axios.delete(
    `${process.env.REACT_APP_API_URL}/api/fun/delete/${funnelId}`
  );
}

// DELETE funnels by ids
export function deletefunnels(ids) {
  return axios.post(`${funnels_URL}/deletes`, { ids });
}
