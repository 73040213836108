import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { register } from "../_redux/authCrud";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";


const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  password: "",
  changepassword: "",
  acceptTerms: false,
};

function Pending(props) {


  return (

    <div className="row">
      <div className="col"></div>
      <div className="col-7">
        <div class="card card-custom bg-success w-100">
          <div class="card-header border-0">
            <div class="card-title">
              <span class="card-icon">
                <i class="flaticon2-check-mark text-white"></i>
              </span>
              <h3 class="card-label text-white">
                Congratulations
              </h3>
            </div>
            {/* <div class="card-toolbar">
            <a href="#" class="btn btn-sm btn-white font-weight-bold">
                <i class="flaticon2-cube"></i> Settings
            </a>
        </div> */}
          </div>
          <div class="separator separator-solid separator-white opacity-20"></div>
          <div class="card-body text-white font-size-h5">
            your registration has been completed. You will have to wait for your upper hand to confirm you. You can then log in to the panel via your username (email) and password.
          </div>
          <div className="w-100 text-center mb-5">
            <Link className="btn btn-primary mx-auto text-center" to="/">
              Back To Login Page
            </Link>

          </div>
        </div>
      </div>
      <div className="col"></div>
    </div>




  );
}

export default injectIntl(connect(null, auth.actions)(Pending));
