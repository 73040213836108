/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import _ from "lodash";

export function HeaderMenu({ layoutProps }) {
    const location = useLocation();
    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? "menu-item-active" : "";
    }
    const { currentState } = useSelector(
        (state) => ({ currentState: state.auth }),
        shallowEqual
    );
    const { user } = currentState;
    // funnels Redux state

    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-left header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        {/*begin::Header Nav*/}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {/*begin::1 Level*/}
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/dashboard')}`}>
                <NavLink className="menu-link" to="/dashboard">
                    <span className="menu-text">Dashboard</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/leads/customers')}`}>
                <NavLink className="menu-link" to="/leads/customers">
                    <span className="menu-text">Sales</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/funnels/fun')}`}>
                <NavLink className="menu-link" to="/funnels/fun">
                    <span className="menu-text">Funnels</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/reps/rep')}`}>
                <NavLink className="menu-link" to="/reps/rep">
                    <span className="menu-text">Reps</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
            <li
                data-menu-toggle={layoutProps.menuDesktopToggle}
                aria-haspopup="true"
                className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/wallet')}`}>
                <NavLink className="menu-link menu-toggle" to="/wallet">
                    <span className="menu-text">Wallet</span>
                    <i className="menu-arrow"></i>
                </NavLink>
                <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                    <ul className="menu-subnav">
                        {/*begin::2 Level*/}

                        <li className={`menu-item ${getMenuItemActive('/wallet')}`}>
                            <NavLink className="menu-link" to="/wallet/transaction">
                                <span className="menu-text">Transactions</span>
                            </NavLink>
                        </li>
                        <li className={`menu-item ${getMenuItemActive('/wallet')}`}>
                            <NavLink className="menu-link" to="/wallet/earn">
                                <span className="menu-text">Earned</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </li>
            {_.some(user.user.groups, { 'name': 'GOD' }) || _.some(user.user.groups, { 'name': 'LEADER' }) ?
                <li
                    data-menu-toggle={layoutProps.menuDesktopToggle}
                    aria-haspopup="true"
                    className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/admin')}`}>
                    <NavLink className="menu-link menu-toggle" to="/admin">
                        <span className="menu-text">Admin</span>
                        <i className="menu-arrow"></i>
                    </NavLink>
                    <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                        <ul className="menu-subnav">
                            {/*begin::2 Level*/}

                            <li className={`menu-item ${getMenuItemActive('/accounts/account')}`}>
                                <NavLink className="menu-link" to="/accounts/account">
                                    <span className="menu-text">VPNS</span>
                                </NavLink>
                            </li>
                            <li className={`menu-item menu-item-rel ${getMenuItemActive('/servers/server')}`}>
                                <NavLink className="menu-link" to="/servers/server">
                                    <span className="menu-text">SERVERS</span>
                                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                                </NavLink>
                            </li>
                            <li className={`menu-item menu-item-rel ${getMenuItemActive('/proinfos/proinfo')}`}>
                                <NavLink className="menu-link" to="/proinfos/proinfo">
                                    <span className="menu-text">Profiles</span>
                                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                                </NavLink>
                            </li>

                        </ul>
                    </div>
                </li>
                : ""}
            {/*end::1 Level*/}
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/help')}`}>
                <a className="menu-link" href={toAbsoluteUrl("/media/help.pdf")} target="_blank">
                    <span className="menu-text">Help</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </a>
            </li>
        </ul>
        {/*end::Header Nav*/}
    </div>;
}
