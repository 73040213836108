import { createSlice } from "@reduxjs/toolkit";

const initialdashboardState = {
  listLoading: false,
  actionsLoading: false,
  funnelCount: '',
  leadCount: '',
  newLeads: '',
  progLeads: '',
  lateLeads: '',
  lostLeads: '',
  repsCount: '',
  funnelPoints: '',
  repPoints: '',
  totalPoints: '',
  twoWeeksAgoLeads: [],
  lastweekLeads: [],
  lastWeekAllLeads: '',
  lastMonthLeads: '',
  lastSixMonthhLeads: '',
  lastYearLeads: '',
  currentMonthLeads: '',
  leadMonthly: [],
  topMonth: {},
  topRep: {},
  topFunnel: {},
  todayLeads: '',
  topReps: {},
  maxLeadMonthly: '',
  lastweekMax: '',
  lastYearMonthName: [],
  pendingRepsCount: '',
  homeTable: '',
  walletBalance: '',
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialdashboardState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getdashboardById
    dashboardFetched: (state, action) => {
      const { funnelCount, leadCount, newLeads, progLeads, lateLeads, lostLeads,
        repsCount, funnelPoints, repPoints, totalPoints, lastweekLeads, lastWeekAllLeads,
        twoWeeksAgoLeads, lastMonthLeads, lastSixMonthhLeads, lastYearLeads, leadMonthly,
        currentMonthLeads, topMonth, topRep, topReps, topFunnel, todayLeads, maxLeadMonthly, lastYearMonthName,
        lastweekMax, pendingRepsCount, walletBalance, earnBalance

      } = action.payload;
      state.actionsLoading = false;
      state.funnelCount = funnelCount;
      state.leadCount = leadCount;
      state.newLeads = newLeads;
      state.progLeads = progLeads;
      state.lateLeads = lateLeads;
      state.lostLeads = lostLeads;
      state.repsCount = repsCount;
      state.funnelPoints = funnelPoints;
      state.repPoints = repPoints;
      state.totalPoints = totalPoints;
      state.lastweekLeads = lastweekLeads;
      state.twoWeeksAgoLeads = twoWeeksAgoLeads;
      state.lastWeekAllLeads = lastWeekAllLeads;
      state.lastMonthLeads = lastMonthLeads;
      state.lastSixMonthhLeads = lastSixMonthhLeads;
      state.lastYearLeads = lastYearLeads;
      state.leadMonthly = leadMonthly;
      state.currentMonthLeads = currentMonthLeads;
      state.topFunnel = topFunnel;
      state.topRep = topRep;
      state.topMonth = topMonth;
      state.todayLeads = todayLeads;
      state.maxLeadMonthly = maxLeadMonthly;
      state.lastYearMonthName = lastYearMonthName;
      state.lastweekMax = lastweekMax;
      state.walletBalance = walletBalance;
      state.earnBalance = earnBalance;
      state.error = null;
    },
    // finddashboard
    dashboardsFetched: (state, action) => {
      const { dashboard, userDashboardInfo } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.dashboard = dashboard;
      state.userDashboardInfo = userDashboardInfo;

    },
    homeTableValueFetched: (state, action) => {
      const { topReps, pendingRepsCount, homeTable } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.topReps = topReps;
      state.pendingRepsCount = pendingRepsCount;
      state.homeTable = homeTable;

    },

    // createdashboard
    dashboardCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    // updatedashboard
    dashboardUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.dashboard.id) {
          return action.payload.dashboard;
        }
        return entity;
      });
    },
    // deletedashboard
    dashboardDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deletedashboards
    dashboardsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },

  }
});
