import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../app/modules/Funnels/_redux/funnels/funnelsActions";
import _ from 'lodash'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { Link } from "react-router-dom";
import ButtonsSwitchTable from "./ButtonsSwitchTable"


export function WaitingReps({ className, reps, pendings }) {

  const user = useSelector((state) => state.auth.user, shallowEqual);
  const homeTable = useSelector((state) => state.dashboard.homeTable, shallowEqual);
  useEffect(() => { }, [user, homeTable]);
  // Methods
  const renderTable = _.map(reps, (rep, i) => {
    return (
      <tr>

        <td className="pr-0">
          <div className="symbol symbol-50 symbol-light mt-1">
            <span className="symbol-label">
              <Link key={i} to={`/reps/rep/${rep.id}/editpage/?tab=basic`}>
                <img className="border rounded" width={50} src={
                  rep.user.profiles.image ? process.env.REACT_APP_API_URL + rep.user.profiles.image :
                    process.env.REACT_APP_API_URL + "/images/profile/blank.png"
                }

                />

              </Link>
            </span>
          </div>
        </td>
        <td className="pl-0">
          <Link key={i} to={`/reps/rep/${rep.id}/editpage/?tab=basic`}
            className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
          >
            {rep.user.profiles.fullname ? rep.user.profiles.fullname : ""}
          </Link>
          <span className="text-muted font-weight-bold text-muted d-block">
            {rep.user.profiles.email ? rep.user.profiles.email : ""}
          </span>
        </td>
        <td>
          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
            {rep.lead_count ? rep.lead_count : ""}
          </span>

        </td>
        <td>
          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
            {rep.funnel_count ? rep.funnel_count : ""}
          </span>

        </td>
        <td>
          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
            {rep.rep_count ? rep.rep_count : ""}
          </span>

        </td>
        <td>
          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
            {rep.status == "0" ? "Pending" : rep.status == "1" ? "Active" : rep.status == "2" ? "Deactive" : ""}
          </span>

        </td>

        <td className="pr-0 text-right">

          <a
            href="#"
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <Link key={i} to={`/reps/rep/${rep.id}/editpage/?tab=basic`}>
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Write.svg"
                  )}
                ></SVG>
              </Link>
            </span>
          </a>

        </td>
      </tr>
    )

  })

  return (
    <div className={`card card-custom ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">
            {homeTable == "0" ? "Pending Reps" : homeTable == "1" ? "Best Reps" : ""}
          </span>
        </h3>
        <div className="card-toolbar">
          <ButtonsSwitchTable user={user} pendings={pendings} />
          {/* <a
            href="#"
            className="btn btn-success font-weight-bolder font-size-sm"
          >
            <span className="svg-icon svg-icon-md svg-icon-white">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Add-user.svg"
                )}
                className="h-50 align-self-center"
              ></SVG>
            </span>
            Add New Member
          </a> */}
        </div>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body py-0">
        {/* begin::Table */}
        <div className="table-responsive">
          <table
            className="table table-head-custom table-vertical-center"
            id="kt_advance_table_widget_1"
          >
            <thead>
              <tr className="text-left">
                <th className="pr-0" style={{ width: "70px" }}>
                  name
                </th>
                <th style={{ minWidth: "150px" }} />
                <th style={{ minWidth: "100px" }}>Sales</th>
                <th style={{ minWidth: "100px" }}>Funnels</th>
                <th style={{ minWidth: "100px" }}>Reps</th>
                <th style={{ minWidth: "100px" }}>Status</th>
                <th className="pr-0 text-right" style={{ minWidth: "50px" }}>
                  action
                </th>
              </tr>
            </thead>
            <tbody>
              {renderTable}
            </tbody>
          </table>
        </div>
        {/* end::Table */}
      </div>
      {/* end::Body */}
    </div>
  );

}
