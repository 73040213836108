/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from 'react-router-dom';
export function TodayLeads({ todayLeads, className, widgetHeight = "175px" }) {
  return (
    <>
      <div
        className={`card card-custom ${className}`}
        style={{ height: widgetHeight }}
      >
        {/* begin::Body */}
        <div className="card-body d-flex align-items-center justify-content-between flex-wrap">
          <div className="mr-2">
            <Link to="/type/4/customers">
              <h1 className="font-weight-bolder text-dark">Today Sales</h1>
            </Link>
          </div>
          <h1 className="font-weight-bolder" style={{ fontSize: "50px" }}>{todayLeads}</h1>
        </div>
        {/* end::Body */}
      </div>
    </>
  );
}
