import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const LeadsPage = lazy(() =>
  import("./modules/Leads/pages/customers/eCommercePage")
);
const LeadsPageType = lazy(() =>
  import("./modules/Leads/pages/customers/eCommercePageType")
);

const FunnelsPage = lazy(() =>
  import("./modules/Funnels/pages/funnelPage")
);

const RepsPage = lazy(() =>
  import("./modules/Reps/pages/repPage")
);

const ServersPage = lazy(() =>
  import("./modules/Servers/pages/eCommercePage")
);

const TransactionsPage = lazy(() =>
  import("./modules/Transactions/pages/eCommercePage")
);

const EarnedPage = lazy(() =>
  import("./modules/Earned/pages/eCommercePage")
);

const ProInfoPage = lazy(() =>
  import("./modules/ProInfos/pages/repPage")
);

const AccountsPage = lazy(() =>
  import("./modules/Accounts/pages/eCommercePage")
);



const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);



export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Route path="/leads" component={LeadsPage} />
        <Route path="/type" component={LeadsPageType} />
        <Route path="/funnels" component={FunnelsPage} />
        <Route path="/reps" component={RepsPage} />
        <Route path="/servers" component={ServersPage} />
        <Route path="/wallet/transaction" component={TransactionsPage} />
        <Route path="/wallet/earn" component={EarnedPage} />
        <Route path="/proinfos" component={ProInfoPage} />
        <Route path="/accounts" component={AccountsPage} />
        <Route path="/user-profile" component={UserProfilepage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
