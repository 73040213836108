import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./DashboardUIHelpers";

const DashboardUIContext = createContext();

export function UseDashboardUIContext() {
  return useContext(DashboardUIContext);
}

export const DashboardUIConsumer = DashboardUIContext.Consumer;

export function DashboardUIProvider({DashboardUIEvents, children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initdashboard = {
    funnelCount:'',
    leadCount:'',
    newLeads:'',
    progLeads:'',
    lateLeads:'',
    lostLeads:'',
    repsCount:'',
    funnelPoints:'',
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initdashboard,
    newdashboardButtonClick: DashboardUIEvents.newdashboardButtonClick,
    openEditdashboardDialog: DashboardUIEvents.openEditdashboardDialog,
    openDeletedashboardDialog: DashboardUIEvents.openDeletedashboardDialog,
    openDeletedashboardDialog: DashboardUIEvents.openDeletedashboardDialog,
    openFetchdashboardDialog: DashboardUIEvents.openFetchdashboardDialog,
    openUpdatedashboardStatusDialog: DashboardUIEvents.openUpdatedashboardStatusDialog
  };

  return <DashboardUIContext.Provider value={value}>{children}</DashboardUIContext.Provider>;
}