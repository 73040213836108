/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
export function PointWidgets({
  className,
  baseColor = "dark",
  widgetHeight = "150px",
  funnelPoints,
  repPoints,
  walletBalance,
  totalPoints,
  earnBalance
}) {
  return (
    <>

      <div
        className={`card card-custom ${className}`}
        style={{ height: widgetHeight, backgroundColor: "#b31d83" }}
      >

        <div className="row mt-3"><div className="col px-25 text-center text-white">You get paid when your balance greater than 500,000 T</div></div>
        <div className="row mt-2 text-center">
          <div className="col text-center">
            <span className="svg-icon svg-icon-3x svg-icon-white ml-n2 mr-3">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Wallet.svg")} />
            </span>
            <Link to="/wallet/transaction"

              className={`text-inverse-${baseColor} font-weight-bold font-size-h4 mt-1`}

            >
              Wallet
            </Link>
            <div
              className={`text-inverse-${baseColor} font-weight-bolder font-size-h3 mt-3`}
            >

              {walletBalance ? <NumberFormat
                value={walletBalance}
                displayType={"text"}
                thousandSeparator={true}
                suffix={" T"}
              /> : 0}
            </div>


          </div>

          <div className="col text-center">
            <Link to="/wallet/earn"

              className={`text-inverse-${baseColor} font-weight-bold font-size-h4 mt-1`}

            >
              <span className="svg-icon svg-icon-3x svg-icon-white ml-n3 mr-3">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/ATM.svg")} />
              </span>
              Earned

            </Link>
            <div
              className={`text-inverse-${baseColor} font-weight-bolder font-size-h3 mt-3`}
            >

              {earnBalance ? <NumberFormat
                value={earnBalance}
                displayType={"text"}
                thousandSeparator={true}
                suffix={" T"}
              /> : 0}
            </div>


          </div>
        </div>

      </div>

    </>
  );
}
