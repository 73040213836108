import axios from "axios";

export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/api/users/login`;
export const REGISTER_URL = `${process.env.REACT_APP_API_URL}/api/users/register`;
export const REQUEST_PASSWORD_URL = "/api/auth/forgot-password";
export const ME_URL = `${process.env.REACT_APP_API_URL}/api/users/profile`;

export function login(username, password) {
  return axios.post(LOGIN_URL, { username, password });
}

export function register(firstName, lastName, fullname, email, phone, gender, password, id) {
  return axios.post(REGISTER_URL, { firstName, lastName, fullname, email, gender, phone, password, id });
}



export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

export function userUpdate(user) {
  return axios.put(`${process.env.REACT_APP_API_URL}/api/users/edit`, { user });
}

export function updateBank(user) {
  return axios.put(`${process.env.REACT_APP_API_URL}/api/users/updatebankinfo`, { user });
}

export function dashboardStatus(user) {
  return axios.put(`${process.env.REACT_APP_API_URL}/api/users/dashstatus`, { user });
}

export function homeTableValue(user) {
  return axios.put(`${process.env.REACT_APP_API_URL}/api/users/hometablevalue`, { user });
}

export function changeUserPassword(user) {
  return axios.put(`${process.env.REACT_APP_API_URL}/api/users/changepassword`, { user });
}

export function requestPassword(email) {
  return axios.post(`${process.env.REACT_APP_API_URL}/api/users/forgetpassword`, { email } );
}

