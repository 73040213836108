import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ModalProgressBar } from "../../controls";
import * as auth from "../../../../app/modules/Auth/_redux/authRedux";
import { homeTableValue } from "../../../../app/modules/Auth/_redux/authCrud";
import * as dashboard from "../../dashboards/_redux/dashboard/dashboardActions";
import * as leads from "../../../../app/modules/Leads/_redux/customers/customersActions"
import * as funnels from "../../../../app/modules/Funnels/_redux/funnels/funnelsActions"
import * as reps from "../../../../app/modules/Reps/_redux/reps/repsActions";
import {
  ToggleButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import { Notice, KTCodeExample } from "../../controls";
import { checkIsActive } from "../../../_helpers";
const inin = {
  status: "", // values => All=""/Susspended=0/Active=1/Pending=2
  type: "", // values => All=""/Business=0/Individual=1
  searchText: "",
  rep: "",
  fun: ""
}

function ButtonsSwitchTable(props) {
  const { user, pendings } = props
  const url = window.location.href
  const lastSegment = url.split("/").pop();
  useEffect(() => {

  }, [lastSegment]);

  const queryLeads = {
    filter: {
      email: "",
      status: "",
      type: "",
      fun: "",
      rep: "",

    },
    sortOrder: "asc",
    sortField: "id",
    pageNumber: 1,
    pageSize: 10
  };

  const queryFunnels = {
    "filter": {
      "term": "",
      "status": "",
      "cats": "",
      "rep": "",
      "priority": ""
    },
    "sortOrder": "asc",
    "sortField": "id",
    "pageNumber": 1,
    "pageSize": 10
  }

  const queryReps = {
    "filter": {
      "term": "",
      "status": "",
      "type": "",
      "fun": "",
      "username": "",
      "upper": ""
    },
    "sortOrder": "asc",
    "sortField": "id",
    "pageNumber": 1,
    "pageSize": 10
  }

  const dispatch = useDispatch();

  // Fields
  const [loading, setloading] = useState(false);


  const saveUser = (values) => {
    setloading(true);
    setTimeout(() => {
      setloading(false);
      dispatch(dashboard.homeTableValue(values.homeTable))
        .then(() => {

          //props.userUpdate(user);
          //dispatch(fetchdashboard());
          setloading(false);

        })
        .catch(() => {

          setloading(false);
        });
    }, 1000);
  };
  // UI Helpers
  const initialValues = {
    homeTable: user.homeTable,
  };
  // const Schema = Yup.object().shape({
  //   emailNotification: Yup.bool(),
  // });
  const formik = useFormik({
    initialValues,
    // validationSchema: Schema,
    onSubmit: (values) => {
      saveUser(values);
    }
  });



  return (
    <form >
      {loading && <ModalProgressBar />}


      {/* begin::Form */}

      <div className="pt-1">


        <ToggleButtonGroup onClick={formik.handleSubmit} name="homeTable" value={formik.values.homeTable} >
          {pendings == 0 ? "" :
            <ToggleButton type="button" onClick={() => {
              formik.values.homeTable = "0"

            }} value={"0"}>Pending Reps</ToggleButton>
          }
          <ToggleButton type="button" onClick={() => {
            formik.values.homeTable = "1"
          }} value={"1"}>Best Reps </ToggleButton>

        </ToggleButtonGroup>



      </div>
      {/* end::Form */}
    </form>
  );
}

export default connect(null, auth.actions)(ButtonsSwitchTable);
