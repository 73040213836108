/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { Link } from 'react-router-dom';

export function LeadsNewWidgets({
  className,
  baseColor = "success",
  widgetHeight = "150px",
  leads,
}) {
  return (
    <>
      <div
        className={`card card-custom bg-${baseColor} ${className}`}
        style={{ height: widgetHeight }}
      >
        <div className="card-body">
          <span className="svg-icon svg-icon-3x svg-icon-white ml-n2">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Bulb1.svg")} />
          </span>
          <div
            className={`text-inverse-${baseColor} font-weight-bolder font-size-h2 mt-3`}
          >
            {leads}
          </div>

          <Link type="button" to={`/type/0/customers/`}
            className={`text-inverse-${baseColor} font-weight-bold font-size-lg mt-1`}>
            Services
          </Link>


        </div>
      </div>
    </>
  );
}
