import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ModalProgressBar } from "../../../../../_metronic/_partials/controls";
import * as auth from "../../../../../app/modules/Auth/_redux/authRedux";
import { dashboardStatus } from "../../../../../app/modules/Auth/_redux/authCrud";
import { fetchdashboard } from "../../../../../_metronic/_partials/dashboards/_redux/dashboard/dashboardActions";
import * as leads from "../../../../../app/modules/Leads/_redux/customers/customersActions"
import * as transactions from "../../../../../app/modules/Transactions/_redux/customers/customersActions"
import * as funnels from "../../../../../app/modules/Funnels/_redux/funnels/funnelsActions"
import * as reps from "../../../../../app/modules/Reps/_redux/reps/repsActions";
import * as actions from "../../../../_partials/dashboards/_redux/dashboard/dashboardActions";
import transitions from "@material-ui/core/styles/transitions";

const inin = {
  status: "", // values => All=""/Susspended=0/Active=1/Pending=2
  type: "", // values => All=""/Business=0/Individual=1
  searchText: "",
  rep: "",
  fun: ""
}


function MyOrAll(props) {
  const url = window.location.href
  const lastSegment = url.split("/").pop();
  useEffect(() => {

  }, [lastSegment]);

  const queryLeads = {
    filter: {
      email: "",
      status: "",
      type: "",
      fun: "",
      rep: "",

    },
    sortOrder: "asc",
    sortField: "id",
    pageNumber: 1,
    pageSize: 10
  };

  const queryTransactions = {
    filter: {
      status: "",
      type: "",
      term: "",

    },
    sortOrder: "asc",
    sortField: "id",
    pageNumber: 1,
    pageSize: 10
  };

  const queryFunnels = {
    "filter": {
      "term": "",
      "status": "",
      "cats": "",
      "rep": "",
      "priority": ""
    },
    "sortOrder": "asc",
    "sortField": "id",
    "pageNumber": 1,
    "pageSize": 10
  }

  const queryReps = {
    "filter": {
      "term": "",
      "status": "",
      "type": "",
      "fun": "",
      "username": "",
      "upper": ""
    },
    "sortOrder": "asc",
    "sortField": "id",
    "pageNumber": 1,
    "pageSize": 10
  }

  const dispatch = useDispatch();

  useEffect(() => {
    // server call for getting dashboard by id
    dispatch(actions.fetchdashboard());
    if (user.pendingRepCount == 0) {
      dispatch(actions.homeTableValue(1))
    } else {
      dispatch(actions.homeTableValue(user.homeTable))
    }
  });

  // Fields
  const [loading, setloading] = useState(false);
  const user = useSelector((state) => state.auth.user, shallowEqual);
  useEffect(() => { }, [user]);
  // Methods

  const saveUser = (values, setStatus, setSubmitting) => {
    setloading(true);
    const updatedUser = Object.assign(user);
    dispatch(props.setUser(updatedUser));
    setTimeout(() => {
      setloading(false);
      setSubmitting(false);
      dashboardStatus(values)
        .then(({ data: { user } }) => {
          props.userUpdate(user);
          dispatch(fetchdashboard());
          if (lastSegment == 'customers') {
            dispatch(leads.fetchCustomers(queryLeads))
          }
          if (lastSegment == 'fun') {
            // dispatch(funnels.cleanfunnels())
            dispatch(funnels.fetchfunnels(queryFunnels))
          }
          if (lastSegment == 'rep') {
            dispatch(reps.fetchReps(queryReps))
          }
          if (lastSegment == 'transaction') {
            dispatch(transactions.fetchCustomers(queryTransactions))
          }

          if (lastSegment == 'profile-overview') {
            dispatch(funnels.fetchfunnelsProfile())
          }
          setloading(false);
          setSubmitting(false);
        })
        .catch(() => {
          setSubmitting(false);
          setloading(false);
        });
    }, 1000);
  };
  // UI Helpers
  const initialValues = {
    dashboard: user.dashboard,
  };
  // const Schema = Yup.object().shape({
  //   emailNotification: Yup.bool(),
  // });
  const formik = useFormik({
    initialValues,
    // validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      saveUser(values, setStatus, setSubmitting);
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    },
  });



  return (
    <>
      {user.user_group == "LEADER" ?
        <form >
          {loading && <ModalProgressBar />}


          {/* begin::Form */}

          <div className="form pt-5" onChange={formik.handleSubmit}>

            <div className="form-group row align-items-center">
              <div className="col p-0">

                <span className="switch switch-dark">
                  <label>
                    <input

                      type="checkbox"
                      name="dashboard"
                      checked={formik.values.dashboard}
                      onChange={formik.handleChange}

                    />
                    <span></span>
                  </label>
                </span>
              </div>
              <div className="col w-110px text-inverse-dark h6 pt-2 pl-0 ">All Data</div>
            </div>
          </div>
          {/* end::Form */}
        </form>
        : ""}
    </>
  );
}

export default connect(null, auth.actions)(MyOrAll);
