/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo } from "react";
import { toAbsoluteUrl } from "../../../_helpers";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../app/modules/Funnels/_redux/funnels/funnelsActions";
import _ from 'lodash'
import { Link } from 'react-router-dom';
import { CopyLinkModalReferral } from "../../../../app/modules/Funnels/pages/funnels/CopyLinkModalReferral";

export function YourRepsWidgets({ className }) {

  const { reps } = useSelector(
    (state) => ({ reps: state.funnels.reps }),
    shallowEqual
  );

  const dispatch = useDispatch();
  useEffect(() => {

    dispatch(actions.fetchfunnelsProfile());


  }, [dispatch]);


  const renderFunnels = _.map(reps, (rep, i) => {

    return (
      <div key={i} className="d-flex flex-wrap align-items-center mb-10">
        {/* begin::Symbol */}
        <div className="symbol symbol-80 symbol-2by2 flex-shrink-0 mr-4">
          <Link key={i} to={`/reps/rep/${rep.id}/editpage/?tab=basic`}>
            <div
              className="symbol-label"
              style={{
                backgroundImage: `url('${process.env.REACT_APP_API_URL}/images/${rep.profilePic}')`
              }}
            ></div>
          </Link>
        </div>
        {/* end::Symbol */}

        {/* begin::Title */}
        <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
          <Link
            to={`/reps/rep/${rep.id}/editpage/?tab=basic`}
            className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
          >
            {rep.fullname}
          </Link>
          <span className="text-muted font-weight-bold font-size-sm">
            Code:{" "}
            <span className="text-primary font-weight-bold">
              <CopyLinkModalReferral code={rep.unique_code} />
            </span>
          </span>
          <span className="text-muted font-weight-bold font-size-sm mt-2">

            Member Since:{" "}
            <span className="text-primary font-weight-bold">{rep.created_at}</span>
          </span>
        </div>
        {/* end::Title */}

        {/* begin::Info */}
        <div className="d-flex align-items-center py-lg-0 py-2">
          <div className="d-flex flex-column text-right">
            <span className="text-dark-75 font-weight-bolder font-size-h4">
              {rep.lead_count}
            </span>
            <span className="text-muted font-size-sm font-weight-bolder">
              Leads
            </span>
          </div>
        </div>

      </div>
    )
  })

  return (
    <>
      {/* begin::List Widget 14 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">
            Your Reps
          </h3>
          <div className="card-toolbar">
            <Link to={`/reps/rep/`}>
              Show All Reps
            </Link>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body pt-2">

          {renderFunnels}


        </div>
        {/* end::Body */}
      </div>
      {/* end::List Widget 14 */}
    </>
  );
}
