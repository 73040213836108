/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { Link } from "react-router-dom";
export function LeadsAllWidgets({
  className,
  baseColor = "dark",
  widgetHeight = "150px",
  leads,
}) {
  return (
    <>
      <div
        className={`card card-custom ${className}`}
        style={{ height: widgetHeight, backgroundColor: "#002f87" }}
      >
        <div className="card-body">
          <span className="svg-icon svg-icon-3x svg-icon-white ml-n2">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")} />
          </span>
          <div
            className={`text-inverse-${baseColor} font-weight-bolder font-size-h2 mt-3`}
          >
            {leads}
          </div>

          <Link to={`/leads/customers`}

            className={`text-inverse-${baseColor} font-weight-bold font-size-h5 mt-1`}

          >
            All Sales
          </Link>
        </div>
      </div>
    </>
  );
}
