import * as requestFromServer from "./repsCrud";
import {repsSlice, callTypes} from "./repsSlice";

const {actions} = repsSlice;

export const fetchReps = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findreps(queryParams)
    .then(response => {
      const { totalCount, entities, funnels} = response.data;
      dispatch(actions.repsFetched({ totalCount, entities, funnels }));
    })
    .catch(error => {
      error.clientMessage = "Can't find reps";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchRepsProfile = (id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findrepsProfile(id)
    .then(response => {
      const {entities} = response.data;
      dispatch(actions.repsProfileFetched({entities}));
    })
    .catch(error => {
      error.clientMessage = "Can't find reps";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchRep = id => dispatch => {
  if (!id) {
    return dispatch(actions.repFetched({ repForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getrepById(id)
    .then(response => {
      const rep = response.data;
      dispatch(actions.repFetched({ repForEdit: rep }));
    })
    .catch(error => {
      error.clientMessage = "Can't find rep";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteRep = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteRep(id)
    .then(response => {
      dispatch(actions.repDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete rep";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createRep = repForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createRep(repForCreation)
    .then(response => {
      const { rep } = response.data;
      dispatch(actions.repCreated({ rep }));
    })
    .catch(error => {
      error.clientMessage = error;
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};


export const updateRep = rep => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateRep(rep)
    .then(() => {
      dispatch(actions.repUpdated({ rep }));
    })
    .catch(error => {
      error.clientMessage = "Can't update rep";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateRepsStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForreps(ids, status)
    .then(() => {
      dispatch(actions.repsStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update reps status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteReps = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteReps(ids)
    .then(() => {
      dispatch(actions.repsDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete reps";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
