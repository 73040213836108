import * as requestFromServer from "./dashboardCrud";
import {dashboardSlice, callTypes} from "./dashboardSlice";

const {actions} = dashboardSlice;

export const fetchdashboards = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .finddashboards(queryParams)
    .then(response => {
      const { totalCount, entities, reps, dashboard } = response.data;
      dispatch(actions.dashboardsFetched({ totalCount, entities, reps, dashboard }));
    })
    .catch(error => {
      error.clientMessage = "Can't find dashboards";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchdashboard = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getdashboardById()
    .then(response => {
      dispatch(actions.dashboardFetched(response.data));
    })
    .catch(error => {
      error.clientMessage = "Can't find dashboard";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const homeTableValue = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .homeTableValueByValue(id)
    .then(response => {
      dispatch(actions.homeTableValueFetched(response.data));
    })
    .catch(error => {
      error.clientMessage = "Can't Fetch hometable value";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deletedashboard = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deletedashboard(id)
    .then(response => {
      dispatch(actions.dashboardDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete dashboard";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createdashboard = dashboardForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createdashboard(dashboardForCreation)
    .then(response => {
      const { dashboard } = response.data;
      dispatch(actions.dashboardCreated({ dashboard }));
    })
    .catch(error => {
      error.clientMessage = error;
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updatedashboard = dashboard => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updatedashboard(dashboard)
    .then(() => {
      dispatch(actions.dashboardUpdated({ dashboard }));
    })
    .catch(error => {
      error.clientMessage = "Can't update dashboard";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

