import axios from "axios";

export const reps_URL = `${process.env.REACT_APP_API_URL}/api/reps`;

// CREATE =>  POST: add a new rep to the server
export function createRep(rep) {
  return axios.post(`${process.env.REACT_APP_API_URL}/api/reps/create`, { rep });
}


// READ
export function getAllreps() {
  return axios.get(reps_URL);
}

export function getrepById(repId) {
  return axios.get(`${process.env.REACT_APP_API_URL}/api/reps/byid/${repId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findreps(queryParams) {
  return axios.post(`${reps_URL}/find`, { queryParams });
}

export function findrepsProfile(id) {
  return axios.get(`${reps_URL}/profile/${id}`);
}

// UPDATE => PUT: update the rep on the server
export function updateRep(rep) {
  return axios.put(`${reps_URL}/edit/${rep.id}`, { rep });
}

// UPDATE Status
export function updateStatusForreps(ids, status) {
  return axios.post(`${reps_URL}/updateStatusForreps`, {
    ids,
    status
  });
}

// DELETE => delete the rep from the server
export function deleteRep(repId) {
  return axios.delete(`${reps_URL}/delete/${repId}`);
}

// DELETE reps by ids
export function deleteReps(ids) {
  return axios.post(`${reps_URL}/deletes`, { ids });
}
