import * as requestFromServer from "./funnelsCrud";
import { funnelsSlice, callTypes } from "./funnelsSlice";

const { actions } = funnelsSlice;

export const fetchfunnels = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findfunnels(queryParams)
    .then(response => {
      const { totalCount, entities, reps, cats, servers } = response.data;
      dispatch(actions.funnelsFetched({ totalCount, entities, reps, cats, servers }));
    })
    .catch(error => {
      error.clientMessage = "Can't find funnels";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const calculateService = (values) => dispatch => {

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .calculateServices(values)
    .then(response => {

      dispatch(actions.funnelFetched(response.data));
    })
    .catch(error => {
      error.clientMessage = "Can't find funnel";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const cleanfunnels = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .cleanAllFunnel()
    .then(response => {
      const { totalCount, entities, reps, cats } = response.data;
      dispatch(actions.funnelsFetched({ totalCount, entities, reps, cats }));
    })
    .catch(error => {
      error.clientMessage = "Can't find funnels";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};


export const fetchMyfunnels = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findmyfunnels(queryParams)
    .then(response => {
      const { totalCount, entities, reps, cats } = response.data;
      dispatch(actions.myFunnelsFetched({ totalCount, entities, reps, cats }));
    })
    .catch(error => {
      error.clientMessage = "Can't find funnels";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchDropdown = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchForDropdown()
    .then(response => {
      dispatch(actions.dropDownFetched(response.data))
    })
    .catch(error => {
      error.clientMessage = "Can't find Dropdowns";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const addReptoFunnel = (queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .addreptofunnel(queryParams)
    .then(response => {
      dispatch(actions.repAdded(response.data))
    })
    .catch(error => {
      error.clientMessage = "Can't add Rep to Funnel";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const deleteRepFromFunnel = (queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .deleterepfromfunnel(queryParams)
    .then(response => {
      dispatch(actions.repRemovedFromFunnel(response.data))
    })
    .catch(error => {
      error.clientMessage = "Can't Remove Rep From Funnel";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchfunnelsProfile = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findfunnelsProfile()
    .then(response => {
      dispatch(actions.funnelsProfileFetched(response.data));
    })
    .catch(error => {
      error.clientMessage = "Can't find funnels";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchfunnel = id => dispatch => {
  if (!id) {
    return dispatch(actions.funnelFetched({ funnelForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getfunnelById(id)
    .then(response => {

      dispatch(actions.funnelFetched(response.data));
    })
    .catch(error => {
      error.clientMessage = "Can't find funnel";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createFunnel = funnelForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createfunnel(funnelForCreation)
    .then(response => {
      const { funnel } = response.data;
      dispatch(actions.repCreated({ funnel }));
    })
    .catch(error => {
      error.clientMessage = error;
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};


export const deletefunnel = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deletefunnel(id)
    .then(response => {
      dispatch(actions.funnelDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete funnel";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createfunnel = funnelForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createfunnel(funnelForCreation)
    .then(response => {
      const { funnel } = response.data;
      dispatch(actions.funnelCreated({ funnel }));
    })
    .catch(error => {
      error.clientMessage = error;
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updatefunnel = funnel => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updatefunnel(funnel)
    .then(() => {
      dispatch(actions.funnelUpdated({ funnel }));
    })
    .catch(error => {
      error.clientMessage = "Can't update funnel";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updatefunnelsStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForfunnels(ids, status)
    .then(() => {
      dispatch(actions.funnelsStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update funnels status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createFunnelPdf = (ids) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createPdfFromFunnels(ids)
    .then(response => {
      const pdf  = response.data;
      dispatch(actions.pdfFileCreated({ pdf }));
    })
    .catch(error => {
      error.clientMessage = "Can't Create Pdf file";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deletefunnels = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deletefunnels(ids)
    .then(() => {
      dispatch(actions.funnelsDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete funnels";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
