import React, { useEffect, useMemo } from "react";
import { ListsWidget10, ListsWidget11, WaitingReps, MixedWidget6, TodayLeads, WhiteAmar, TodayLeadsWidgets, MixedWidget10, MixedWidget11, MixedWidget12, TilesWidget1, TilesWidget3, AmarRed, TilesWidget10, TilesWidget11, RepsWidgets, LeadsAllWidgets, LeadsNewWidgets, LeadsProgWidgets, LeadsLateWidgets, LeadsLostWidgets, PointWidgets, TilesWidget12, TilesWidget13, TilesWidget14, FunnelWidgets } from "../widgets";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "./_redux/dashboard/dashboardActions";
import { UseDashboardUIContext } from "./DashboardUIContext";
export function Demo2Dashboard() {
    const DashboardUIContext = UseDashboardUIContext();
    //  const dashboardUIProps = useMemo(() => {
    //   return {
    //     initdashboard: DashboardUIContext.initdashboard,
    //   };
    // }, [DashboardUIContext]);
    const { user } = useSelector((state) => state.auth, shallowEqual);
    // // dashboard Redux state
    const dispatch = useDispatch();
    // const { actionsLoading, dashboardForEdit } = useSelector(
    //   (state) => ({
    //     actionsLoading: state.dashboard.actionsLoading,
    //     dashboardForEdit: state.dashboard.dashboardForEdit,
    //   }),
    //   shallowEqual
    // );

    useEffect(() => {
        // server call for getting dashboard by id
        dispatch(actions.fetchdashboard());
        if (user.pendingRepCount == 0) {
            dispatch(actions.homeTableValue(1))
        } else {
            dispatch(actions.homeTableValue(user.homeTable))
        }
    }, [dispatch]);

    // //server request for saving dashboard
    // const savedashboard = (dashboard) => {
    //   if (!user.id) {
    //     // server request for creating dashboard
    //     dispatch(actions.createdashboard(dashboard)).then(() => onHide());
    //   } else {
    //     console.log("SEND UPDATE REQUEST !!!!")
    //     // server request for updating dashboard
    //     dispatch(actions.updatedashboard(dashboard)).then(() => onHide());

    //   }
    // };
    const {
        // FOR ROW ONE
        funnelCount, leadCount, newLeads, progLeads,
        lateLeads, lostLeads, repsCount, funnelPoints,
        repPoints, totalPoints, lastweekLeads, twoWeeksAgoLeads,
        lastWeekAllLeads, lastMonthLeads, lastSixMonthhLeads,
        lastYearLeads, leadMonthly, currentMonthLeads, topFunnel, topRep, topMonth, todayLeads, topReps, maxLeadMonthly,
        lastYearMonthName, lastweekMax, pendingRepsCount, walletBalance, earnBalance
        // FOR ROW TWO

    } = useSelector((state) => state.dashboard, shallowEqual);

    return <>
        {/* begin::Dashboard */}

        {/* begin::Row */}
        <div className="row">

            <div className="col-xl-4">
                <AmarRed
                    lastweekLeads={lastweekLeads}
                    twoWeeksAgoLeads={twoWeeksAgoLeads}
                    lastWeekAllLeads={lastWeekAllLeads}
                    lastMonthLeads={lastMonthLeads}
                    lastSixMonthhLeads={lastSixMonthhLeads}
                    lastYearLeads={lastYearLeads}
                    lastweekMax={lastweekMax}
                    className="gutter-b card-stretch"
                    chartColor="primary" />

            </div>
            <div className="col-xl-8">
                <div className="row">
                    <div className="col-xl-3">
                        <LeadsAllWidgets leads={leadCount} className="gutter-b" widgetHeight="150px" />
                    </div>
                    <div className="col-xl-9">

                        <TodayLeads todayLeads={todayLeads} className="gutter-b" widgetHeight="150px" />
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-6">
                        <div className="row">
                            <div className="col-xl-6">
                                <LeadsNewWidgets leads={newLeads} className="gutter-b" iconColor="success" widgetHeight="150px" />
                            </div>
                            <div className="col-xl-6">
                                <LeadsProgWidgets leads={progLeads} className="gutter-b" widgetHeight="150px" />

                            </div>

                        </div>
                        <div className="row">

                            <div className="col-xl-6">
                                <LeadsLateWidgets leads={lateLeads} className="gutter-b" iconColor="success" widgetHeight="150px" />
                            </div>

                            <div className="col-xl-6">
                                <LeadsLostWidgets leads={lostLeads} className="gutter-b" widgetHeight="150px" />

                            </div>

                        </div>

                    </div>

                    <div className="col-xl-6">

                        <div className="row">


                            <div className="col-xl-6">
                                <FunnelWidgets className="gutter-b" widgetHeight="150px" funCount={funnelCount} />
                            </div>
                            <div className="col-xl-6">
                                <RepsWidgets leads={repsCount} className="gutter-b" iconColor="success" widgetHeight="150px" />
                            </div>

                            <div className="col-xl-12">
                                <PointWidgets funnelPoints={funnelPoints} repPoints={repPoints} totalPoints={totalPoints} walletBalance={walletBalance} earnBalance={earnBalance}
                                    className="gutter-b" baseColor="primary" widgetHeight="150px" />

                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </div>
        {/* end::Row */}

        {/* begin::Row */}
        <div className="row">
            <div className="col-lg-6 col-xxl-4">
                <WhiteAmar
                    leadMonthly={leadMonthly}
                    currentMonthLeads={currentMonthLeads}
                    topFunnel={topFunnel}
                    topRep={topRep}
                    topMonth={topMonth}
                    maxLeadMonthly={maxLeadMonthly}
                    lastYearMonthName={lastYearMonthName}
                    className="gutter-b card-stretch"
                    chartColor="danger" />

            </div>

            <div className="col-lg-6 col-xxl-8">
                <WaitingReps
                    reps={topReps}
                    pendings={pendingRepsCount}
                    className="card-stretch gutter-b" />
            </div>
        </div>
        {/* end::Row */}

        {/* begin::Row */}
        {/* <div className="row">
            <div className="col-xl-4">
                <MixedWidget10 className="card-stretch gutter-b" />
            </div>

            <div className="col-xl-4">
                <MixedWidget11 className="card-stretch gutter-b" />
            </div>

            <div className="col-xl-4">
                <MixedWidget12 className="card-stretch gutter-b" />
            </div>
        </div> */}
        {/* end::Row */}

        {/* begin::Row */}
        {/* <div className="row">
            <div className="col-lg-6">
                <ListsWidget10 className="card-stretch gutter-b" />
            </div>
            <div className="col-lg-6">
                <ListsWidget11 className="card-stretch gutter-b" />
            </div>
        </div> */}
        {/* end::Row */}

        {/* end::Dashboard */}
    </>;
}
